// extracted by mini-css-extract-plugin
export var card = "FeatureCard-module--card--01ef6";
export var cardContainer = "FeatureCard-module--cardContainer--0b4be";
export var cardDetailsBar = "FeatureCard-module--cardDetailsBar--4a9a7";
export var cardDetailsBarReversed = "FeatureCard-module--cardDetailsBarReversed--ea236";
export var cardFeaturedProjectHeader = "FeatureCard-module--cardFeaturedProjectHeader--af821";
export var cardInfo = "FeatureCard-module--cardInfo--e29bb";
export var cardInfoLinks = "FeatureCard-module--cardInfoLinks--d46b1";
export var cardInfoProjectTitle = "FeatureCard-module--cardInfoProjectTitle--42a25";
export var cardInfoTechStack = "FeatureCard-module--cardInfoTechStack--86bc5";
export var cardInfoTechStackText = "FeatureCard-module--cardInfoTechStackText--28e11";
export var cardProjectImage = "FeatureCard-module--cardProjectImage--28e85";
export var socialLink = "FeatureCard-module--socialLink--3cdba";